import React from 'react';
import { Goal, ScoringRules, UserTeams } from '../../types';

interface FirstLastGoalCardProps {
    goal: Goal,
    teamAllocs: UserTeams[],
    scoringRules: ScoringRules,
    title: string
}

function FirstLastGoalCard({
  goal, teamAllocs, scoringRules, title,
}: FirstLastGoalCardProps) {
  const userTeam = teamAllocs.find(
    (team) => team.team1Id === goal.team.toString()
    || team.team2Id === goal.team.toString()
  );

  const score = scoringRules.fastestGoal;

  return (
    <div className="first-last-goals-card">
      <div className="group">
        <p>{title}</p>
        <div className="team-names">
          {goal.playerName}
        </div>
      </div>
      <div className="first-last-goals-team">
        <img src={goal.teamLogo} alt={goal.teamName} />
        <p>{goal.teamName}</p>
        <div className="team-names">
          {userTeam?.name}
        </div>
        <div className={`score ${score > 0 ? 'positive' : 'neutral'}`}>
          <span className="score-value">{score >= 0 ? `+${score}` : score}</span>
        </div>
      </div>
      <div className="first-last-goals-time">{goal.extra ? `${goal.time} + ${goal.extra} minutes` : `${goal.time} minutes`}</div>
    </div>
  );
}

export default FirstLastGoalCard;
