import React from 'react';
import { usePreTournamentOdds } from '../../queries';
import Spinner from '../Spinner/Spinner';
import './TeamLists.css';

function TeamLists() {
  const [loading, odds] = usePreTournamentOdds();

  return (
    <div className="team-lists-container">
      <h2>Team Lists</h2>
      <p>Verified 9th June 2024 (Paddy Power)</p>
      {loading || !odds ? <Spinner />
        : (
          <div className="team-lists">
            <div className="team-list">
              <h3>Team 1</h3>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Odds Winner</th>
                    <th>Odds Runner-Up</th>
                  </tr>
                </thead>
                <tbody>
                  {odds && odds.team1 && odds.team1.map((team) => (
                    <tr key={team.name}>
                      <td>{team.name}</td>
                      <td>{team.oddsWinner}</td>
                      <td>{team.oddsRunnerUp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="team-list">
              <h3>Team 2</h3>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Odds Winner</th>
                    <th>Odds Runner-Up</th>
                  </tr>
                </thead>
                <tbody>
                  {odds && odds.team2 && odds.team2.map((team) => (
                    <tr key={team.name}>
                      <td>{team.name}</td>
                      <td>{team.oddsWinner}</td>
                      <td>{team.oddsRunnerUp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </div>
  );
}

export default TeamLists;
