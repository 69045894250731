import React from 'react';
import { useScoringRules } from '../../queries';
import Spinner from '../Spinner/Spinner';
import './ScoringSystem.css';

function ScoringSystem() {
  const [loading, scoringRules] = useScoringRules();

  return (
    <div className="scoring-system">
      <h2 className="scoring-system__title">Scoring System</h2>
      {loading || !scoringRules ? <Spinner />
        : (
          <table className="scoring-system__table">
            <thead>
              <tr className="scoring-system__header-row">
                <th className="scoring-system__header-cell">Criteria</th>
                <th className="scoring-system__header-cell">Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(scoringRules).map(([criteria, points]) => (
                <tr key={criteria} className="scoring-system__body-row">
                  <td className="scoring-system__body-cell">{criteria}</td>
                  <td className="scoring-system__body-cell">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default ScoringSystem;
