import { BlobServiceClient } from '@azure/storage-blob';
import { useEffect, useState } from 'react';
import {
  AllTeamOdds, Fixture, GroupWinner, MatchStats, ScoringRules, TopScorer, UserTeams,
} from './types';

const sweepstakesAccount = process.env.REACT_APP_SWEEPSTAKES_STORAGE_ACCOUNT ?? '';
const sweepstakesSasToken = process.env.REACT_APP_SWEEPSTAKES_STORAGE_SAS ?? '';
const sweepstakesContainerName = process.env.REACT_APP_SWEEPSTAKES_STORAGE_CONTATINER ?? '';

const blobServiceClient = new BlobServiceClient(`https://${sweepstakesAccount}.blob.core.windows.net/?${sweepstakesSasToken}`);
const containerClient = blobServiceClient.getContainerClient(sweepstakesContainerName);

const useBlobData = <T>(blobName: string): [boolean, T | undefined] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T | undefined>(undefined);

  const blobFetcher = async (): Promise<any> => {
    setLoading(true);
    try {
      const blobClient = containerClient.getBlockBlobClient(blobName);
      const downloadBlockBlobResponse = await blobClient.download(0);
      const body = await downloadBlockBlobResponse.blobBody;
      const text = await body?.text();
      return JSON.parse(text ?? '');
    } catch (error) {
      console.error('Error fetching blob:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const blobData = await blobFetcher();
      setData(blobData);
    };

    fetchData();
  }, [blobName]);

  return [loading, data];
};

const useBlobFolder = <T>(folderName: string): [boolean, T[]] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T[]>([]);

  const fetchBlobsInFolder = async (): Promise<T[]> => {
    setLoading(true);
    try {
      const blobDataArray: T[] = [];
      for await (const blob of containerClient.listBlobsFlat({ prefix: folderName })) {
        const blobClient = containerClient.getBlockBlobClient(blob.name);
        const downloadBlockBlobResponse = await blobClient.download(0);
        const body = await downloadBlockBlobResponse.blobBody;
        const text = await body?.text();
        if (text) {
          blobDataArray.push(JSON.parse(text) as T);
        }
      }
      return blobDataArray;
    } catch (error) {
      console.error('Error fetching blobs:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const blobsData = await fetchBlobsInFolder();
      setData(blobsData);
    };

    fetchData();
  }, [folderName]);

  return [loading, data];
};

export const useScoringRules = () => useBlobData<ScoringRules>('scoring.json');

export const useUserTeams = () => useBlobData<UserTeams[]>('teamAssignments.json');

export const useTodaysFixtures = (date: string): [boolean, Fixture[] | undefined] => {
  const [loading, fixtures] = useBlobData<Fixture[]>(`fixtures/${date}.json`);
  return [
    loading,
    fixtures?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  ];
};

export const useAllFixtures = (): [boolean, Fixture[]] => {
  const [loading, fixtures] = useBlobFolder<Fixture[]>('fixtures/');
  return [loading, fixtures.flat()];
};

export const useMatchStats = (matchId: string) => useBlobData<MatchStats>(`matchStats/${matchId}.json`);

export const useAllMatchStats = (): [boolean, MatchStats[]] => {
  const [loading, matchStats] = useBlobFolder<MatchStats[]>('matchStats/');
  return [loading, matchStats.flat()];
};

export const usePreTournamentOdds = () => useBlobData<AllTeamOdds>('preTournamentOdds.json');

export const useTopScorers = () => useBlobData<TopScorer[]>('topScorers.json');

export const useGroupWinners = () => useBlobData<GroupWinner[]>('groupWinners.json');
