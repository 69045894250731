import React from 'react';
import { useUserTeams } from '../../queries';
import Spinner from '../Spinner/Spinner';
import './UserTeams.css';

function UserTeams() {
  const [loading, userTeams] = useUserTeams();

  return (
    <div className="user-teams-container">
      <h2>Team Allocations</h2>
      {loading || !userTeams ? <Spinner />
        : (
          <table className="user-teams-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Team 1</th>
                <th>Team 2</th>
              </tr>
            </thead>
            <tbody>
              {userTeams && userTeams.length > 0 && userTeams.map((userTeam) => (
                <tr key={userTeam.name}>
                  <td>{userTeam.name}</td>
                  <td>{userTeam.team1}</td>
                  <td>{userTeam.team2}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default UserTeams;
