import React, { useEffect, useState } from 'react';
import { useTodaysFixtures, useUserTeams } from '../../queries';
import Spinner from '../Spinner/Spinner';
import FixtureCard from './FixtureCard';
import './FixturesOfTheDay.css';

function FixturesOfTheDay() {
  const todaysDate = new Date().toISOString().split('T')[0];

  const [fixturesLoading, fixtures] = useTodaysFixtures(todaysDate);
  const [teamAllocationsLoading, teamAllocations] = useUserTeams();

  const loading = fixturesLoading || teamAllocationsLoading || !fixtures || !teamAllocations;

  // State to hold the countdown value
  const [countdown, setCountdown] = useState(0);

  // Calculate next update time based on current time
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      // Calculate time until next update (when minutes is divisible by 5)
      const timeUntilNextUpdate = (5 - (minutes % 5)) * 60;
      setCountdown(timeUntilNextUpdate - seconds);
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  // Format countdown time to display
  const formatCountdown = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = Math.floor(countdown % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Extract the date from the first fixture for the header
  const headerDate = new Date(todaysDate).toLocaleDateString();

  const endDate = new Date('2024-07-16');
  const isPastEndDate = new Date(todaysDate) >= endDate;

  return (
    <div className="fixtures-with-teams-container">
      {isPastEndDate ? <h2>Thanks for playing!</h2> : (
        <>
          <h2>
            Fixtures of the Day -
            {' '}
            {headerDate}
          </h2>
          <div className="countdown">
            Time until next update:
            {' '}
            {formatCountdown()}
          </div>
          {loading ? <Spinner /> : fixtures.length > 0 && fixtures.map((fixture) => (
            <FixtureCard key={fixture.id} fixture={fixture} teamAllocs={teamAllocations ?? []} />
          ))}
        </>
      )}
    </div>
  );
}

export default FixturesOfTheDay;
