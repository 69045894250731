import React from 'react';
import { GroupWinner, ScoringRules, UserTeams } from '../../types';

interface GroupWinnerCardProps {
    groupWinner: GroupWinner,
    teamAllocs: UserTeams[],
    scoringRules: ScoringRules,
}

function GroupWinnerCard({
  groupWinner, teamAllocs, scoringRules,
}: GroupWinnerCardProps) {
  const userTeam = teamAllocs.find(
    (team) => team.team1Id === groupWinner.teamId.toString()
    || team.team2Id === groupWinner.teamId.toString()
  );

  const score = userTeam?.team1Id === groupWinner.teamId.toString()
    ? scoringRules.t1WinGroup
    : scoringRules.t2WinGroup;

  return (
    <div className="group-winners-card">
      <div className="group">
        <p>{groupWinner.group}</p>
      </div>
      <div className="group-winners-team">
        <img src={groupWinner.teamLogo} alt={groupWinner.teamName} />
        <p>{groupWinner.teamName}</p>
        <div className="team-names">
          {userTeam?.name}
        </div>
        <div className={`score ${score > 0 ? 'positive' : 'neutral'}`}>
          <span className="score-value">{score >= 0 ? `+${score}` : score}</span>
        </div>
      </div>
      <div className="group-winners-points">{`${groupWinner.points} points`}</div>
    </div>
  );
}

export default GroupWinnerCard;
