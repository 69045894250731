import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; Praptrick</p>
        <p>
          &copy;
          {new Date().getFullYear()}
          {' '}
          Euro 2024 Sweepstakes Results. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
