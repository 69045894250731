import React from 'react';
import { ScoringRules, TopScorer, UserTeams } from '../../types';

interface TopScorerCardProps {
    topScorer: TopScorer,
    teamAllocs: UserTeams[],
    scoringRules: ScoringRules,
    index: number
}

function TopScorerCard({
  topScorer, teamAllocs, scoringRules, index,
}: TopScorerCardProps) {
  const findNamesByTeam = (team: string) => teamAllocs && teamAllocs.length > 0 && teamAllocs
    .filter(
      (allocation) => allocation.team1Id === team.toString()
    || allocation.team2Id === team.toString()
    )
    .map((allocation) => allocation.name)
    .join(', ');

  const userName = findNamesByTeam(topScorer.teamId);
  const score = index === 0 ? scoringRules.goldenBoot : 0;

  return (
    <div className="top-scorers-card">
      <div className="top-scorers-name">
        <img src={topScorer.photo} alt={topScorer.name} />
        <p>{topScorer.name}</p>
      </div>
      <div className="top-scorers-team">
        <img src={topScorer.teamLogo} alt={topScorer.teamName} />
        <p>{topScorer.teamName}</p>
        <div className="team-names">
          {userName}
        </div>
        <div className={`score ${score > 0 ? 'positive' : 'neutral'}`}>
          <span className="score-value">{score >= 0 ? `+${score}` : score}</span>
        </div>
      </div>
      <div className="top-scorers-goals">{`${topScorer.goals} goals`}</div>
    </div>
  );
}

export default TopScorerCard;
