import React from 'react';
import { useGroupWinners, useScoringRules, useUserTeams } from '../../queries';
import Spinner from '../Spinner/Spinner';
import GroupWinnerCard from './GroupWinnerCard';
import './GroupWinners.css';

function GroupWinners() {
  const [groupWinnersLoading, groupWinners] = useGroupWinners();

  const [rulesLoading, rules] = useScoringRules();
  const [userTeamsLoading, userTeams] = useUserTeams();

  const loading = groupWinnersLoading
   || rulesLoading
   || userTeamsLoading
   || !groupWinners
   || !rules
   || !userTeams;

  return (
    <div className="group-winners">
      <h2 className="group-winners__title">Group Winners</h2>
      {loading ? <Spinner /> : groupWinners.map((winner) => (
        <GroupWinnerCard
          key={winner.group}
          groupWinner={winner}
          scoringRules={rules}
          teamAllocs={userTeams}
        />
      ))}
    </div>
  );
}

export default GroupWinners;
