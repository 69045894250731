import React, { useEffect, useState } from 'react';
import {
  useAllMatchStats, useGroupWinners, useScoringRules, useTopScorers, useUserTeams,
} from '../../queries';
import { Goal, Scores } from '../../types';
import { calculateResults, calculateScores } from '../../utils/scoringUtils';
import Spinner from '../Spinner/Spinner';
import './Scoreboard.css';

function getRowClass(index: number, totalRows: number): string {
  if (index === 0) {
    return 'gold';
  } if (index === 1) {
    return 'silver';
  } if (index === 2) {
    return 'bronze';
  } if (index === totalRows - 1) {
    return 'wooden-spoon';
  }
  return '';
}

function Scoreboard() {
  const [userTeamsLoading, userTeams] = useUserTeams();
  const [matchStatsLoading, matchStats] = useAllMatchStats();
  const [rulesLoading, rules] = useScoringRules();
  const [topScorerersLoading, topScorers] = useTopScorers();
  const [groupWinnersLoading, groupWinners] = useGroupWinners();
  const [scores, setScores] = useState<Scores[]>([]);

  const loading = userTeamsLoading
   || matchStatsLoading
   || rulesLoading
   || topScorerersLoading
   || groupWinnersLoading;

  useEffect(() => {
    if (loading || !userTeams || !matchStats || !rules || !topScorers || !groupWinners) {
      return;
    }

    const goals = matchStats.reduce<Goal[]>((acc, matchStat) => [
      ...acc,
      ...matchStat.goalEvents
    ], []);

    const earliestGoal = goals.sort((a, b) => a.time - b.time)[0];
    const latestGoal = goals.sort((a, b) => b.extra - a.extra)[0];

    const teamResults = calculateResults(userTeams, matchStats);
    const calculatedScores = calculateScores(
      teamResults,
      userTeams,
      rules,
      topScorers[0].teamId,
      groupWinners,
      earliestGoal,
      latestGoal
    );
    calculatedScores.sort(
      (a, b) => (b.score + b.groupWinner + b.earliestGoal + b.latestGoal + b.goldenBoot)
      - (a.score + a.groupWinner + a.earliestGoal + a.latestGoal + a.goldenBoot)
    );
    setScores(calculatedScores);
  }, [userTeams, matchStats, rules]);

  return (
    <div className="scoreboard">
      <h2 className="scoreboard__title">Scoreboard</h2>
      <p className="scoreboard-info">GW: Group Winner; G: Golden Boot/Fastest Goal/Latest Goal</p>
      <p className="scoreboard-info">Sorted by Total</p>
      {loading ? <Spinner />
        : (
          <table className="scoreboard__table">
            <thead>
              <tr className="scoreboard__header-row">
                <th className="scoreboard__header-cell">User Team</th>
                <th className="scoreboard__header-cell">Score</th>
                <th className="scoreboard__header-cell">GW</th>
                <th className="scoreboard__header-cell">G</th>
                <th className="scoreboard__header-cell">Total</th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score, index) => (
                <tr key={score.name} className={`scoreboard__body-row ${getRowClass(index, scores.length)}`}>
                  <td className="scoreboard__body-cell">{score.name}</td>
                  <td className="scoreboard__body-cell">{score.score}</td>
                  <td className="scoreboard__body-cell">{score.groupWinner}</td>
                  <td className="scoreboard__body-cell">{score.goldenBoot + score.earliestGoal + score.latestGoal}</td>
                  <td className="scoreboard__body-cell">{score.score + score.goldenBoot + score.groupWinner + score.earliestGoal + score.latestGoal}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default Scoreboard;
