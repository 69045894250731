import React from 'react';
import logo from '../../assets/logo.png';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <title>Euro 2024 Sweepstakes</title>
      <div className="header-logo">
        <img src={logo} alt="" />
        <p>Euro 2024 Sweepstakes</p>
      </div>
      <nav className="header-nav">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/stats">Team Stats</a></li>
          <li><a href="/scores">Scores</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
