import React, { useEffect } from 'react';
import { appInsights } from '../../appInsights';
import FirstLastGoals from '../../components/FirstLastGoals/FirstLastGoals';
import FixturesOfTheDay from '../../components/FixturesOfTheDay/FixturesOfTheDay';
import Footer from '../../components/Footer/Footer';
import GroupWinners from '../../components/GroupWinners/GroupWinners';
import Header from '../../components/Header/Header';
import TeamLists from '../../components/Odds/TeamLists';
import Scoreboard from '../../components/Scoreboard/Scoreboard';
import ScoringSystem from '../../components/ScoringSystem/ScoringSystem';
import TopScorers from '../../components/TopScorers/TopScorers';
import UserTeams from '../../components/UserTeams/UserTeams';
import './Euro2024.css';
import Euro2024ErrorBoundary from './Euro2024ErrorBoundary';

function Euro2024() {
  useEffect(() => {
    const pageName = window.location.pathname;
    appInsights.trackPageView({ name: pageName });
  }, []);

  return (
    <div className="footy24-container">
      <Header />
      <Euro2024ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
        <main className="footy24-main">
          <FixturesOfTheDay />
          <Scoreboard />
          <TopScorers />
          <GroupWinners />
          <FirstLastGoals />
          <ScoringSystem />
          <TeamLists />
          <UserTeams />
        </main>
      </Euro2024ErrorBoundary>
      <Footer />
    </div>
  );
}

export default Euro2024;
