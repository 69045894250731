import React from 'react';
import { useAllMatchStats, useScoringRules, useUserTeams } from '../../queries';
import { Goal } from '../../types';
import Spinner from '../Spinner/Spinner';
import FirstLastGoalCard from './FirstLastGoalCard';
import './FirstLastGoals.css';

function FirstLastGoals() {
  const [matchStatsLoading, matchStats] = useAllMatchStats();

  const [rulesLoading, rules] = useScoringRules();
  const [userTeamsLoading, userTeams] = useUserTeams();

  const loading = matchStatsLoading
  || rulesLoading
  || userTeamsLoading
  || !matchStats
  || !rules
  || !userTeams;

  const goals = matchStats.reduce<Goal[]>((acc, matchStat) => [
    ...acc,
    ...matchStat.goalEvents
  ], []);

  const earliestGoal = goals.sort((a, b) => a.time - b.time)[0];
  const latestGoal = goals.sort((a, b) => b.extra - a.extra)[0];

  return (
    <div className="first-last-goals">
      <h2 className="first-last-goals__title">Quickest and Latest Goals</h2>
      {loading ? <Spinner />
        : (
          <>
            <FirstLastGoalCard
              key={earliestGoal.time}
              goal={earliestGoal}
              scoringRules={rules}
              teamAllocs={userTeams}
              title="Quickest Goal"
            />
            <FirstLastGoalCard
              key={latestGoal.time}
              goal={latestGoal}
              scoringRules={rules}
              teamAllocs={userTeams}
              title="Latest Goal"
            />
          </>
        )}
    </div>
  );
}

export default FirstLastGoals;
