/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useMatchStats, useScoringRules, useUserTeams } from '../../queries';
import { Fixture, Scores, UserTeams } from '../../types';
import { calculateResults, calculateScores } from '../../utils/scoringUtils';
import './FixturesOfTheDay.css';

interface FixtureCardProps {
  fixture: Fixture,
  teamAllocs: UserTeams[]
}

function FixtureCard({ fixture, teamAllocs }: FixtureCardProps) {
  const [matchStatsLoading, matchStats] = useMatchStats(fixture.id.toString());
  const [userTeamsLoading, userTeams] = useUserTeams();
  const [scoringRulesLoading, scoringRules] = useScoringRules();
  const [scores, setScores] = useState<Scores[]>([]);

  const isLoading = matchStatsLoading || userTeamsLoading || scoringRulesLoading;

  useEffect(() => {
    if (isLoading || !userTeams || !matchStats || !scoringRules) {
      return;
    }

    const teamResults = calculateResults(userTeams, [matchStats]);
    const calculatedScores = calculateScores(teamResults, userTeams, scoringRules);
    setScores(calculatedScores);
  });

  const findNamesByTeam = (team: string) => teamAllocs && teamAllocs.length > 0 && teamAllocs
    .filter(
      (allocation) => allocation.team1Id === team.toString()
    || allocation.team2Id === team.toString()
    )
    .map((allocation) => allocation.name)
    .join(', ');

  const homeName = findNamesByTeam(fixture.homeId);
  const awayName = findNamesByTeam(fixture.awayId);

  const homeScore = scores.find((s) => s.name === homeName)?.score ?? 0;
  const awayScore = scores.find((s) => s.name === awayName)?.score ?? 0;

  return (
    <div key={fixture.id} className="fixture-card">
      <div className="team">
        <img src={fixture.homeLogo} alt={`${fixture.home} logo`} />
        <span>{fixture.home}</span>
        <div className="team-names">
          {homeName}
        </div>
        <div className={`score ${homeScore < 0 ? 'negative' : homeScore > 0 ? 'positive' : 'neutral'}`}>
          <span className="score-value">{homeScore >= 0 ? `+${homeScore}` : homeScore}</span>
        </div>
      </div>
      <div>
        <div className="fixture-time">
          {new Date(fixture.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </div>
        <div className="fixture-info">
          <div className="home-score">{matchStats?.score.home ?? '-'}</div>
          <div className="versus">vs</div>
          <div className="away-score">{matchStats?.score.away ?? '-'}</div>
        </div>
      </div>
      <div className="team">
        <img src={fixture.awayLogo} alt={`${fixture.away} logo`} />
        <span>{fixture.away}</span>
        <div className="team-names">
          {awayName}
        </div>
        <div className={`score ${awayScore < 0 ? 'negative' : awayScore > 0 ? 'positive' : 'neutral'}`}>
          <span className="score-value">{awayScore >= 0 ? `+${awayScore}` : awayScore}</span>
        </div>
      </div>
    </div>
  );
}
export default FixtureCard;
