import React from 'react';
import { useScoringRules, useTopScorers, useUserTeams } from '../../queries';
import Spinner from '../Spinner/Spinner';
import TopScorerCard from './TopScorerCard';
import './TopScorers.css';

function TopScorers() {
  const [topScorersLoading, topScorers] = useTopScorers();
  const [rulesLoading, rules] = useScoringRules();
  const [userTeamsLoading, userTeams] = useUserTeams();

  const loading = topScorersLoading
   || rulesLoading
   || userTeamsLoading
   || !topScorers
   || !rules
   || !userTeams;

  return (
    <div className="top-scorers">
      <h2 className="top-scorers__title">Golden Boot</h2>
      <p>Not updated every day</p>
      {loading ? <Spinner /> : topScorers.slice(0, 3).map(
        (topScorer, index) => (
          <TopScorerCard
            key={topScorer.name}
            topScorer={topScorer}
            scoringRules={rules}
            teamAllocs={userTeams}
            index={index}
          />
        )
      )}
    </div>
  );
}

export default TopScorers;
